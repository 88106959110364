import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import Building from '../../img/building.svg';
import Phone from '../../img/phone.svg';
import LowkeyIcon from '../../img/lowkey_icon.svg';

const SetupProcess = () => {
  return (
    <div class="px-10">
      <div class="flex flex-col items-center pb-5 sm:pb-8 pt-1">
        <SectionH2 title={'Setup Process'} />
        <div class="text-white text-center font-space-grotesk-regular max-w-4xl space-y-3">
          <p>
            Lowkey provides you a virtual phone number which will interface securely with your existing buzzer system.
          </p>
          <p>Get started with Lowkey today in three easy steps.</p>
        </div>
      </div>

      <div class="flex flex-col items-center sm:hidden pt-3">
        <a
          href="https://www.youtube.com/watch?v=IyvUmsBqltQ"
          onClick={() => {}}
          class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 hover:bg-black hover:text-green-lowkey border-green-lowkey bg-green-lowkey text-black focus:ring-gray-800"
        >
          <svg class="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
          </svg>
          {`Watch Video (<57s)`}
        </a>
      </div>

      <div class="flex-col items-center hidden sm:flex pb-6">
        <div class="rounded-lg border-2 border-separate ßborder-green-lowkey flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-4/6 max-w-4xl">
          <div class="aspect-w-16 aspect-h-9">
            <iframe
              loading="lazy"
              title={'How To Get Started With Lowkey'}
              class="rounded-lg w-full h-full"
              src="https://www.youtube.com/embed/IyvUmsBqltQ?start=0&autoplay=0&mute=0å&loop=0&color=white&controls=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=IyvUmsBqltQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div class="sm:pt-5 sm:flex sm:flex-row sm:justify-center">
        <div class="flex flex-col sm:flex-row sm:space-x-8 lg:space-x-12 justify-center space-y-16 sm:space-y-0 px-2 sm:px-0 pt-16 sm:py-0 max-w-7xl">
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'1. Get a virtual phone number'}
            description={'Purchase a subscription in the Lowkey app to receive a virtual number'}
            imageSrc={Phone}
            imageAlt={'phone icon'}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'2. Send to your property manager'}
            description={
              'Send the virtual number to your property manager to have the buzzer dial this number rather than your personal phone'
            }
            imageSrc={Building}
            imageAlt={'building icon'}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'3. Start using Lowkey'}
            description={'Once the buzzer is configured, use Lowkey to control your building door'}
            imageSrc={LowkeyIcon}
            imageAlt={'lock icon'}
          />
        </div>
      </div>
    </div>
  );
};

export default SetupProcess;
