import React, { useState } from 'react';

const FeatureDescription = ({ title, descriptions, expanded, theme = 'dark' }) => {
  const [isOpen, setOpen] = useState(expanded);
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';

  const handleExpand = () => {
    setOpen(!isOpen);
  };

  return (
    <button class="min-w-full text-left" onClick={handleExpand}>
      <div
        class={`${
          isOpen ? 'bg-green-lowkey/20' : ''
        } lg:w-76 flex flex-col space-y-3 rounded-lg border border-green-lowkey sm:w-72 xl:w-96 ${textColor} p-5`}
      >
        <div class="flex flex-row justify-between">
          <p class="font-space-grotesk-medium text-xl">{title}</p>
          <svg
            data-accordion-icon
            class={`h-6 w-6 ${isOpen ? 'rotate-180' : ''} shrink-0`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        {descriptions.map((description) => (
          <p class={`${isOpen ? '' : 'hidden'} text-md font-space-grotesk-regular ${textColor}`}>{description}</p>
        ))}
      </div>
    </button>
  );
};

export default FeatureDescription;
