import React, { useState, useEffect } from 'react';
import SectionH2 from '../heading/SectionH2';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PayoutCalculator = () => {
  const [referrals, setReferrals] = useState(200);
  const [estimatedPayout, setEstimatedPayout] = useState({ oneMonth: 0 });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const calculatePayout = (referralCount) => {
      const revenuePerUserMonth1 = 116.5;

      const commission = calculateCommission(referralCount);

      const oneMonth = referralCount * revenuePerUserMonth1 * commission;

      setEstimatedPayout({ oneMonth });
      updateChartData(oneMonth);
    };

    calculatePayout(referrals);
  }, [referrals]);

  const updateChartData = (oneMonthPayout) => {
    setChartData([
      { name: '', Payout: 0 },
      { name: '1 Month', Payout: oneMonthPayout },
    ]);
  };

  const calculateCommission = (referralCount) => {
    let totalCommission = 0;

    if (referralCount <= 10) {
      totalCommission = referralCount * 0.3;
    } else if (referralCount <= 100) {
      totalCommission = 10 * 0.3 + (referralCount - 10) * 0.4;
    } else if (referralCount <= 1000) {
      totalCommission = 10 * 0.3 + 90 * 0.4 + (referralCount - 100) * 0.5;
    } else {
      totalCommission = 10 * 0.3 + 90 * 0.4 + 900 * 0.5 + (referralCount - 1000) * 1.0;
    }

    return totalCommission / referralCount || 0;
  };

  const handleInputChange = (e) => {
    let count = parseInt(e.target.value, 10) || 0;
    count = Math.min(99999, count); // Cap referrals at 99999
    setReferrals(count);
  };

  return (
    <div className="mx-auto max-w-4xl px-10">
      <SectionH2 title={'Affiliate Payout Calculator'} />
      <div className="mt-10 flex flex-col-reverse sm:mt-8 sm:flex-col">
        <div className="">
          <div className="-mx-8 mt-8 sm:mt-4">
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="colorPayout" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#41E1B0" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#41E1B0" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
                <Tooltip
                  formatter={(value) => `$${value.toLocaleString()}`}
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: '1px solid #4B5563',
                    fontFamily: 'SpaceGrotesk-Regular',
                  }}
                  itemStyle={{ color: '#F9FAFB', fontFamily: 'SpaceGrotesk-Medium' }}
                  labelStyle={{ color: '#41E1B0', fontFamily: 'SpaceGrotesk-Medium' }}
                />
                <Legend />
                <Area type="monotone" dataKey="Payout" stroke="#41E1B0" fillOpacity={1} fill="url(#colorPayout)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div>
          <div className="-mx-10 -mr-12 flex flex-row overflow-x-auto px-4 sm:px-10">
            <table className="w-full table-auto text-white">
              <thead>
                <tr>
                  <th className="py-2 pr-1 font-semibold sm:px-2">Referrals</th>
                  <th className="py-2 pl-1 font-semibold sm:px-2">Commission</th>
                  <th className="px-4 py-2 font-semibold">1 Month Payout</th>
                </tr>
              </thead>
              <tbody>
                <tr className={`font-space-grotesk-regular`}>
                  <td className="border px-4 py-2">{referrals.toLocaleString()}</td>
                  <td className="border px-4 py-2">{`${(calculateCommission(referrals) * 100).toFixed(2)}%`}</td>
                  <td className="border px-4 py-2">{`$${estimatedPayout.oneMonth.toLocaleString()}`}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="-ml-6 -mr-10 mt-4 flex flex-grow flex-row text-xs text-gray-400 sm:mx-0 sm:px-0 sm:text-sm">
            *Payout calculations are estimates based on past Lowkey subscriber behavior and current pricing.
          </p>
        </div>

        <div className="my-4 -mx-6 flex-col space-y-3 sm:mx-0">
          <label htmlFor="referralCount" className="text-lg font-medium text-white">
            Number of Referrals
          </label>
          <input
            type="number"
            id="referralCount"
            value={referrals.toString().replace(/^0+(?=\d)/, '')}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-gray-300 bg-white py-2 px-4 font-space-grotesk-medium text-gray-700 shadow-sm focus:border-green-lowkey focus:outline-none focus:ring-2 focus:ring-green-lowkey focus:ring-opacity-50"
            min="0"
            max="99999"
          />
        </div>
      </div>
    </div>
  );
};

export default PayoutCalculator;
