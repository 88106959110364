import React, { useState } from 'react';
import SectionH1 from '../heading/SectionH1';
import { useAnalyticsEventTracker } from '../../utils/analytics';
import ModalVideo from 'react-modal-video';
import Spline from '@splinetool/react-spline';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { useLocation } from 'react-router-dom';

const Hero = ({ channel }) => {
  const eventTracker = useAnalyticsEventTracker('Hero');
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const saleTerm = searchParams.get('sale');
  const referralCode = searchParams.get('referreral_code') || searchParams.get('referral_code');
  const getHeroTitle = () => {
    if (channel === 'instacart') {
      return 'Tired of Buzzing in Instacart Deliveries?';
    } else if (channel === 'doordash' || channel === 'ubereats') {
      return `Tired of Buzzing in ${channel === 'doordash' ? 'DoorDash' : 'UberEats'} Deliveries?`;
    } else if (channel === 'caregivers') {
      return `Simplify Access for Your Elderly Parents' Caregivers`;
    } else if (channel === 'buzz_in_clients') {
      return 'Streamline Client Buzzer Access For Your Business';
    } else if (channel === 'delivery') {
      return 'Say Goodbye To Missed Deliveries';
    } else {
      return 'Apartments Can Be Smart Homes, Too';
    }
  };

  const getHeroSubtext = () => {
    if (channel === 'instacart') {
      return 'Let Lowkey automatically buzz in your Instacart shopper. No more rushing to the phone or missing groceries.';
    } else if (channel === 'doordash' || channel === 'ubereats') {
      const serviceName = channel === 'doordash' ? 'DoorDash' : 'UberEats';
      return `Lowkey ensures your ${serviceName} order arrives hot. Automatic buzzer entry means no more cold food or missed deliveries.`;
    } else if (channel === 'caregivers') {
      return 'Provide secure, convenient access for healthcare workers and assistants caring for your elderly parents in their condo or apartment.';
    } else if (channel === 'buzz_in_clients') {
      return 'Stop wasting time buzzing in clients. Lowkey automates buzzer entry for your business, saving you time and hassle.';
    } else if (channel === 'delivery') {
      return 'Automatically buzz in your deliveries without answering your phone';
    } else {
      return 'Control your apartment or condo buzzer from the convenience of your smartphone';
    }
  };
  const heroTitle = getHeroTitle();
  const heroSubtext = getHeroSubtext();

  return (
    <>
      <ModalVideo
        animationSpeed={10}
        channel="youtube"
        autoplay={1}
        isOpen={showModal}
        videoId="iOGTP-SGpFk"
        onClose={() => setShowModal(false)}
      />

      <div class="overflow-clip">
        <div
          className={`mx-auto flex max-w-screen-xl flex-row px-4 ${
            saleTerm || referralCode ? 'pt-16' : 'pt-8'
          } pb-2 text-center ${saleTerm || referralCode ? 'md:pt-28' : 'md:pt-16'} md:pb-8 lg:px-12 lg:pb-12`}
        >
          <div class="text-center lg:flex lg:w-2/3 lg:flex-grow lg:flex-col lg:justify-center lg:pr-5 lg:text-left xl:pr-20">
            <SectionH1 title={heroTitle} />
            <p class="text-md mb-1 font-space-grotesk-regular font-normal text-gray-400 sm:mb-0 sm:px-16 lg:px-0 lg:text-lg xl:text-xl">
              {heroSubtext}
            </p>
            <p class="text-md mb-8 font-space-grotesk-regular font-normal text-gray-400 sm:px-16 md:mb-12 lg:px-0 lg:text-lg xl:mb-16 xl:text-xl">
              Integrate with your building's buzzer in minutes, no installation required
            </p>

            <div class="mb-8 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 lg:mb-8 lg:justify-start">
              <div className="flex flex-row justify-between space-x-4">
                <a
                  href="#download"
                  onClick={() => eventTracker('download_navigate', undefined, 'ClickButton')}
                  class="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 inline-flex flex-grow items-center justify-center rounded-lg bg-gray-900 py-3 px-5 text-center text-base font-medium text-white focus:ring-4 sm:hidden"
                >
                  Download
                </a>
                <a
                  href="#features"
                  onClick={() => eventTracker('learn_more_navigate', undefined, 'ClickButton')}
                  class="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 inline-flex flex-grow items-center justify-center rounded-lg bg-gray-900 py-3 px-5 text-center text-base font-medium text-white focus:ring-4 sm:hidden"
                >
                  Learn more
                  <svg
                    class="ml-2 -mr-1 h-5 w-5 rotate-90"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
              <a
                href="#features"
                onClick={() => eventTracker('learn_more_navigate', undefined, 'ClickButton')}
                class="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 hidden items-center justify-center rounded-lg bg-gray-900 py-3 px-5 text-center text-base font-medium text-white focus:ring-4 sm:inline-flex"
              >
                Learn more
                <svg
                  class="ml-2 -mr-1 h-5 w-5 rotate-90"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.youtube.com/watch?v=iOGTP-SGpFk"
                onClick={() => eventTracker('click_video_link', undefined, 'ClickButton')}
                class="inline-flex items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800 sm:hidden"
              >
                <svg
                  class="mr-2 -ml-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                </svg>
                {`Watch video`}
              </a>
              <button
                onClick={() => {
                  eventTracker('click_video_link', undefined, 'ClickButton');
                  setShowModal(true);
                }}
                class="hidden items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800 sm:inline-flex"
              >
                <svg
                  class="mr-2 -ml-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                </svg>
                {`Watch video`}
              </button>
            </div>
          </div>

          <div class="invisible flex border-separate flex-col rounded-lg border-2 border-green-lowkey lg:visible lg:w-1/4 xl:w-72">
            <div class="aspect-w-7 aspect-h-15 relative">
              <div class="invisible absolute top-20 -z-50 h-full w-full sm:visible" style={{ width: 575, left: -150 }}>
                <Spline scene="https://prod.spline.design/dqjISrzfyXgKo2Gi/scene.splinecode" />
              </div>
              <iframe
                loading="lazy"
                title={'Lowkey App Store Video'}
                class="h-full w-full rounded-lg"
                src="https://www.youtube.com/embed/ryShAAqu31s?start=4&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=ryShAAqu31s"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
