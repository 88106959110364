import React from 'react';
import SectionH2 from '../heading/SectionH2';
import Jewels from '../../img/jewels.png';
import Squiggles from '../../img/squiggles.png';
import { useAnalyticsEventTracker } from '../../utils/analytics';

const DownloadFromShare = () => {
  const eventTracker = useAnalyticsEventTracker('DownloadFromShare');

  return (
    <div>
      <div class="max-w-screen-4xl mx-auto flex flex-row justify-center lg:px-12">
        <div
          style={{ boxShadow: 'inset 0 0 30px 12px #41E1B0' }}
          class="w-5/6 rounded-3xl border border-green-lowkey py-20 px-8 sm:w-4/5 sm:py-16 sm:px-0"
        >
          <div class="flex flex-row justify-center sm:px-10">
            <div class="hidden w-1/5 sm:visible sm:flex sm:flex-col sm:justify-center">
              <img loading="lazy" src={Jewels} alt={'Jewel decorations on the left'} class="object-contain" />
            </div>
            <div class="text-center sm:flex sm:w-2/3 sm:flex-col sm:justify-center">
              <div className="px-4 pb-1 sm:px-0 sm:pb-0">
                <SectionH2 title={'Get Lowkey for your buzzer'} />
              </div>
              <div class="space-y-3 pb-10 font-space-grotesk-regular text-white sm:space-y-2 sm:pb-10 2xl:pb-12">
                <p class="font-space-grotesk-medium font-bold">
                  Now residents can have the convenience of a smart buzzer system in minutes
                </p>
                <p class="font-space-grotesk-medium font-bold">
                  <span class="bg-yellow-lowkey box-decoration-clone px-2 text-black">
                    No installation required, uses your existing building's buzzer
                  </span>
                </p>
                <p className="sm:pt-0">Tap below to learn how you can upgrade your apartment access experience today</p>
              </div>

              <div class="flex flex-col justify-center px-2 sm:flex-row sm:justify-center">
                {/* <a
                  href="#pricing"
                  onClick={() => {}}
                  class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 hover:bg-black hover:text-green-lowkey border-green-lowkey bg-green-lowkey text-black focus:ring-gray-800"
                >
                  Learn More
                </a> */}
                <a
                  href="https://www.lowkeybuzzer.com"
                  onClick={() => eventTracker('share_learn_more', undefined, 'ClickButton')}
                  className="mt-3 rounded bg-yellow-lowkey py-2 px-4 font-syne-bold text-black"
                >
                  Learn more
                </a>
              </div>
            </div>

            <div class="hidden w-1/5 sm:visible sm:flex sm:flex-col sm:justify-center">
              <img loading="lazy" src={Squiggles} alt={'Jewel decorations on the left'} class="object-contain" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadFromShare;
