import React, { forwardRef } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';

const Section = forwardRef((props, ref) => {
  return (
    // <Link smooth to={props.id}>
    <section id={props.id} ref={ref}>
      <div class={`overflow-x-clip ${props.topPadding ? props.topPadding : 'pt-28'} sm:pb-14`}>{props.children}</div>
    </section>
    // </Link>
  );
});

export default Section;
