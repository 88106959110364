import React from 'react';
import Footer from './components/sections/Footer';

const Cookies = () => {
    return (
        <>
            <section className="text-left text-slate-300 py-8 px-8 bg-white">
                <div name="termly-embed" data-id="c21cbc57-522c-4b15-be17-a7bc68dcff7a" data-type="iframe"></div>
                <script type="text/javascript">{(function (d, s, id) {
                    var js, tjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = "https://app.termly.io/embed-policy.min.js";
                    tjs.parentNode.insertBefore(js, tjs);
                }(document, 'script', 'termly-jssdk'))}</script>
            </section>
            <Footer />
        </>
    );
};

export default Cookies;