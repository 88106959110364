import React from 'react';
import SectionH2 from '../heading/SectionH2';
import CheckmarkDark from '../../img/checkmark_dark.svg';
import Checkmark from '../../img/checkmark.svg';
import { useAnalyticsEventTracker } from '../../utils/analytics';

const Pricing = ({ theme = 'light' }) => {
  const eventTracker = useAnalyticsEventTracker('BusinessPricing');
  //   const disableMonthly = false;

  const pricingPlans = [
    {
      name: 'Pro',
      //   description: 'Bulk pricing & priority support for businesses managing >100 units',
      features: [
        'Automated guest check-in',
        'Sync with Guesty reservations',
        'Monitor property access',
        'Share access with your team',
        'Customized welcome greetings',
      ],
      missingFeatures: [],
      //   monthlyPrice: `${(19.47 / 1).toFixed(2)} x unit`,
      //   annualPrice: `${(137.97).toFixed(2)} x unit`,
      annualPriceMonthly: `$15 flat fee + $10 / unit`,
      color: '#FFFFFF',
      cta: 'Contact Sales',
    },
    {
      name: 'Enterprise',
      description: 'For businesses managing >100 units',
      features: [
        'All features included in Pro',
        'Dedicated technical support contact',
        'Prioritized feature requests',
        'Bulk pricing discount',
      ],
      missingFeatures: [],
      //   monthlyPrice: (35.89 / 1).toFixed(2),
      //   annualPrice: (238.47).toFixed(2),
      //   annualPriceMonthly: '$15 / unit',
      color: '#41E1B0',
      cta: 'Contact Sales',
    },
  ];

  const pricingComponent = (plan, index) => {
    return (
      <div
        key={index}
        style={{
          boxShadow: `inset 0 0 20px 1px ${plan.color}`,
          borderColor: `${plan.color}`,
          borderWidth: 1,
        }}
        className="flex h-full w-full flex-grow flex-row justify-center rounded-lg bg-gray-900 text-white sm:w-2/3 sm:max-w-md md:w-96"
      >
        <div className="flex w-full flex-col space-y-5 py-6 px-9 text-center font-space-grotesk-regular lg:w-96">
          <div className="flex flex-col space-y-1">
            <h3 className="font-syne-bold text-3xl font-bold">{plan.name}</h3>
            <p className="text-lg">{plan.description}</p>
          </div>
          <div className="flex flex-col space-y-3">
            {plan.features.map((feature, index) => (
              <li key={index} className="flex flex-row items-center justify-between text-left text-sm">
                {feature}
                <img src={Checkmark} alt={'white checkmark icon'} class="h-3 w-3 object-contain" />
              </li>
            ))}
            {plan.missingFeatures.map((feature, index) => (
              <li key={index} className="flex flex-row items-center justify-start text-sm line-through opacity-50">
                {feature}
              </li>
            ))}
          </div>
          <div className="flex flex-grow flex-col justify-end">
            {!!plan.annualPriceMonthly && (
              <div>
                <div>
                  <span>{plan.annualPriceMonthly}</span>
                </div>
                <span className="text-gray-400">{`(billed monthly)`}</span>
              </div>
            )}
            {plan.cta === 'Contact Sales' ? (
              <a
                href="https://7i25h3fs1dk.typeform.com/to/P5KYB1he"
                // href="mailto:contact@lowkeybuzzer.com"
                onClick={() => {
                  eventTracker('Lead', plan.name, 'Contact');
                  // eventTracker('contact_sales_business', plan.name, 'EmailContactSales');
                }}
                className="mt-3 rounded bg-yellow-lowkey py-2 px-4 font-syne-bold text-black"
              >
                {plan.cta}
              </a>
            ) : (
              <a
                href="https://7i25h3fs1dk.typeform.com/to/P5KYB1he"
                // href="https://app.lowkeybuzzer.com"
                onClick={() => {
                  eventTracker('Lead', plan.name, 'Contact');
                  // eventTracker('get_started_pricing_business', plan.name, 'Download');
                  // eventTracker('get_started_business', plan.name, 'Download');
                }}
                className="mt-3 rounded bg-yellow-lowkey py-2 px-4 font-syne-bold text-black"
              >
                {plan.cta}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-8">
      <div className="flex flex-col items-center pb-5 sm:pb-11">
        <SectionH2 title={'Plans & Pricing'} theme={theme} />
        <div className="flex w-full max-w-2xl flex-row flex-wrap items-center justify-around gap-2 sm:flex-row sm:flex-nowrap sm:justify-around sm:gap-0 sm:space-y-0 xl:max-w-3xl">
          <div className="hidden flex-row space-x-3 sm:flex">
            <img src={CheckmarkDark} alt={'white checkmark icon'} class="mx-auto object-contain" />
            <p>60 day money back guarantee</p>
          </div>

          <div className="flex flex-grow flex-row justify-center sm:hidden">
            <div className="flex flex-row space-x-3">
              <img src={CheckmarkDark} alt={'white checkmark icon'} class="mx-auto object-contain" />
              <p>60 day money back guarantee</p>
            </div>
          </div>
        </div>
        <div class="max-w-4xl space-y-4 pt-4 text-center">
          <p class="font-space-grotesk-regular">Try risk-free with our 100% satisfaction guarantee.</p>
          <p class="font-space-grotesk-regular">Receive a full refund within 60 days, no questions asked.</p>
        </div>
      </div>

      <div class="flex flex-grow flex-row justify-center overflow-x-auto sm:-mx-8 sm:px-8 sm:pt-5">
        <div className="flex w-full flex-col items-center space-y-4 sm:space-y-6 lg:flex-row lg:justify-center lg:space-y-0 lg:space-x-4 xl:space-x-6 2xl:space-x-8">
          {pricingPlans.map((plan, index) => pricingComponent(plan, index))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
