// TimerContext.js

import { createContext, useContext, useState, useEffect } from 'react';

export const TimerContext = createContext();

export const useTimer = () => {
  const context = useContext(TimerContext);

  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }

  return context;
};

export const TimerProvider = ({ children }) => {
  const [timeLeftToClaimTrial, setTimeLeftToClaimTrial] = useState(15 * 60);

  useEffect(() => {
    const timerId = setInterval(() => setTimeLeftToClaimTrial((time) => Math.max(60, time - 1)), 1000);
    return () => clearInterval(timerId);
  }, []);

  return <TimerContext.Provider value={timeLeftToClaimTrial}>{children}</TimerContext.Provider>;
};
