import React from 'react';
import XLogo from '../../img/X_logo.svg';
import XLogoDark from '../../img/X_logo_dark.svg';
import Instagram from '../../img/instagram.svg';
import InstagramDark from '../../img/instagram_dark.svg';
import Facebook from '../../img/facebook.svg';
import FacebookDark from '../../img/facebook_dark.svg';
import TikTok from '../../img/tiktok.svg';
import TikTokDark from '../../img/tiktok_dark.svg';
import Email from '../../img/email.svg';
import EmailDark from '../../img/email_dark.svg';
import YouTube from '../../img/youtube.svg';
import YouTubeDark from '../../img/youtube_dark.svg';

const SocialMedia = ({ theme = 'dark' }) => {
  const isLightTheme = theme === 'light';
  return (
    <div class="mx-auto flex flex-col py-16 px-4">
      <div class="flex h-7 flex-row items-center justify-center space-x-6 xl:h-8">
        <a href="https://instagram.com/lowkeybuzzer" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? InstagramDark : Instagram}
            class="h-7 w-7 object-contain"
            alt="Instagram"
          />
        </a>
        <a href="https://youtube.com/@lowkeybuzzer" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? YouTubeDark : YouTube}
            class="h-7 w-7 object-contain xl:h-8 xl:w-8"
            alt="YouTube"
          />
        </a>
        <a href="https://tiktok.com/@lowkey_buzzer" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? TikTokDark : TikTok}
            class="h-7 w-7 object-contain xl:h-8 xl:w-8"
            alt="TikTok"
          />
        </a>
        <a href="https://x.com/lowkeybuzzer" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? XLogoDark : XLogo}
            class="object-contai h-6 w-6 xl:h-7 xl:w-7"
            alt="X"
          />
        </a>
        <a href="https://www.facebook.com/lowkeybuzzer" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? FacebookDark : Facebook}
            class="h-7 w-7 object-contain xl:h-8 xl:w-8"
            alt="Facebook"
          />
        </a>
        <a href="mailto:contact@lowkeybuzzer.com" class="flex">
          <img
            loading="lazy"
            src={isLightTheme ? EmailDark : Email}
            class="h-7 w-7 object-contain xl:h-8 xl:w-8"
            alt="Email"
          />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
