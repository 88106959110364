import React from 'react';

const SectionH2 = ({ title, customAlignment, theme = 'dark' }) => {
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';
  return (
    <h2
      class={`mb-4 font-syne-bold text-4xl leading-none tracking-tight sm:mb-6 ${
        customAlignment ? customAlignment : 'text-center'
      } ${textColor}`}
    >
      {title}
    </h2>
  );
};

export default SectionH2;
