import React from 'react';
import SectionH2 from '../heading/SectionH2';
import Ghost from '../../img/ghost.svg';
import Amazon from '../../img/amazon.svg';
import Adobe from '../../img/adobe.svg';
import UCLA from '../../img/ucla.svg';
import GeorgiaTech from '../../img/georgia_tech.svg';
import GhostDark from '../../img/ghost_dark.svg';
import AmazonDark from '../../img/amazon_dark.svg';
import AdobeDark from '../../img/adobe_dark.svg';
import UCLADark from '../../img/ucla_dark.svg';
import GeorgiaTechDark from '../../img/georgia_tech_dark.svg';
import TyFace from '../../img/tyface.png';
import AlexFace from '../../img/alexface.png';

const OurStory = ({ theme = 'dark' }) => {
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';
  const isLightTheme = theme === 'light';

  return (
    <div className="relative">
      <div
        style={{ height: 1200, width: 1200, left: -700, top: -390 }}
        class="absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 sm:invisible"
      />
      <div
        style={{ height: 1772, width: 1772, left: -1100, top: -600 }}
        class="invisible absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 sm:flex 2xl:invisible"
      />
      <div
        style={{ height: 1772, width: 1772, left: -1000, top: -600 }}
        class="invisible absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 2xl:visible"
      />
      <div className="px-10">
        <div className="flex flex-row justify-center">
          <div className="flex max-w-7xl flex-col pb-5 pt-1 sm:flex-row sm:justify-center sm:pb-16">
            <div className="sm:w-1/3">
              <div className="flex flex-col items-center sm:items-start">
                <SectionH2 customAlignment={'text-center sm:text-left'} title={'Our Story'} theme={theme} />
              </div>
            </div>
            <div
              className={`text-center sm:flex sm:text-left ${textColor} flex-col justify-center space-y-4 sm:w-2/3 sm:flex-none sm:gap-4 sm:space-y-0`}
            >
              <div className="flex flex-row justify-around pt-6 sm:pt-0 ">
                <div className="flex flex-col space-y-2">
                  <img
                    className="h-28 w-28 rounded-full border border-green-lowkey"
                    loading="lazy"
                    alt="Alex's portrait"
                    src={AlexFace}
                  />
                  <p className="text-md text-center font-syne-bold text-white">Alex</p>
                </div>
                <div className="flex flex-col space-y-2">
                  <img
                    className="h-28 w-28 rounded-full border border-green-lowkey"
                    loading="lazy"
                    alt="Ty's portrait"
                    src={TyFace}
                  />
                  <p className="text-md text-center font-syne-bold text-white">Ty</p>
                </div>
              </div>
              <p>
                In 2020, we were just two best friends stuck at home and frustrated with our outdated buzzer system.
                With the combined power of boredom and technology, we built Lowkey to improve our buzzer experience and
                wanted to share it with the world!
              </p>
              <p className="font-bold">
                Thanks so much for supporting our small business and our dream to become indie app developers!
              </p>

              <p>
                We’re 100% self funded and operated so without you none of this would be possible. We thank you from the
                bottom of our heart for your support!
              </p>
              <p>- ❤️ Ty & Alex</p>

              <br />
              <p>Here are some of the places we’ve worked and studied:</p>
              <div className="space-y-8 pt-3">
                <div className="flex flex-row items-center justify-around">
                  <img
                    loading="lazy"
                    src={isLightTheme ? GhostDark : Ghost}
                    className="h-11 object-fill"
                    alt="Snap logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? AmazonDark : Amazon}
                    className="h-6 object-fill"
                    alt="Amazon logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? AdobeDark : Adobe}
                    className="h-10 object-fill"
                    alt="Adobe logo"
                  />
                </div>
                <div className="flex flex-row items-center justify-evenly">
                  <img
                    loading="lazy"
                    src={isLightTheme ? UCLADark : UCLA}
                    className="h-6 object-fill"
                    alt="UCLA logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? GeorgiaTechDark : GeorgiaTech}
                    className="h-7 object-fill"
                    alt="Georgia Tech logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
