import React from 'react';
import SectionH2 from '../heading/SectionH2';
import ExpandableFAQ from '../core/ExpandableFAQ';

const BusinessFAQ = ({ theme = 'dark' }) => {
  return (
    <div class="flex flex-row justify-center px-10">
      <div class="flex max-w-7xl flex-col sm:flex-row">
        <div class="sm:w-1/3">
          <div class="flex flex-col items-center pb-5 pt-1 sm:pb-16">
            <SectionH2
              customAlignment={'text-center sm:text-left'}
              title={'Frequently Asked Questions'}
              theme={theme}
            />
          </div>
        </div>
        <div class="flex-row justify-center space-y-4 sm:flex sm:w-2/3 sm:flex-none sm:gap-4 sm:space-y-0">
          <div class="flex flex-col gap-4 sm:flex-col sm:flex-wrap">
            <ExpandableFAQ
              expanded={true}
              title={'How will guests interact with the buzzer after I set up Lowkey?'}
              descriptions={[
                'Guests will first dial your rental unit either via your unit code or the buzzer directory as usual.',
                "After dialing your unit, guests will be prompted to enter the Passcode you've given them from the Lowkey app. Once the guest has entered the Passcode using the buzzer's numpad, they will be buzzed in by Lowkey.",
              ]}
              theme={theme}
            />
            <div class="sm:hidden">
              <ExpandableFAQ
                expanded={false}
                title={'Is the Lowkey app secure?'}
                descriptions={[
                  'Yes, the Lowkey app is designed to be secure and protect your privacy. All communication between the app and the buzzer is encrypted to ensure the security of your building and the privacy of your personal information.',
                  'Lowkey also includes an Activity History feature that helps to further ensure the security of your building. This feature allows you to view a record of all activity, including when individuals have accessed the building and when they have been granted access.',
                ]}
                theme={theme}
              />
            </div>
            <ExpandableFAQ
              expanded={false}
              title={'What is a virtual number?'}
              descriptions={[
                'A virtual number is a phone number that is not tied to a specific physical phone or SIM card. Instead, it is a virtual number that is associated with your Lowkey account and is used to interface securely with your buzzer system.',
              ]}
              theme={theme}
            />
            <ExpandableFAQ
              expanded={false}
              title={'How do I set up a virtual number?'}
              descriptions={[
                'Currently, we are in a Closed Beta Program, you will need to request access to get setup and we will reach out to determine if you are a good fit.',
                'Once you are setup, you will be able to add properties and receive a virtual number for each property that you add. Then send the created virtual number(s) to the building manager to have it added to the buzzer. From then on, the buzzer will dial this virtual number rather than your personal phone.',
              ]}
              theme={theme}
            />
          </div>
          <div class="flex flex-col gap-4 sm:flex-col sm:flex-wrap">
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'Is the Lowkey app secure?'}
                descriptions={[
                  'Yes, the Lowkey app is designed to be secure and protect your privacy. All communication between the app and the buzzer is encrypted to ensure the security of your building and the privacy of your personal information.',
                  'Lowkey also includes an Activity History feature that helps to further ensure the security of the building. This feature allows you to view a record of all activity, including when individuals have accessed the building and when they have been granted access.',
                ]}
                theme={theme}
              />
            </div>
            <ExpandableFAQ
              expanded={false}
              title={'Is Lowkey compatible with all buzzer systems?'}
              descriptions={[
                'Yes, Lowkey is designed to work with all buzzer systems that dial a phone number. As long as your buzzer is set up to dial a phone number, you can use the Lowkey app to control it.',
              ]}
              theme={theme}
            />
            <ExpandableFAQ
              expanded={false}
              title={'How do I try the Lowkey app for Self Check-In?'}
              descriptions={[
                'Request Access! We are in a Closed Beta Program at the moment, so we have limited availability. Once you request access, we will reach out to see if you are a good fit.',
              ]}
              theme={theme}
            />
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'Can I forward calls for buzzer access to multiple phones?'}
                descriptions={[
                  'Yes, with Lowkey you can easily forward calls for buzzer access to multiple phones. In the Lowkey app, you can add other account admins with their personal phone numbers, then they will be able to answer calls from the buzzer and grant access to your building when passcodes are not activated.',
                ]}
                theme={theme}
              />
            </div>
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'How does the Passcode feature work?'}
                descriptions={[
                  "Passcodes are 3-4 digits and can be easily changed or revoked at any time, and/or can by synced with your booking calendar and smart lock, if you have one on your unit's door.",
                  "You can share a passcode with the individuals you want to grant access to your building. When calling your unit's number on the buzzer, they will be prompted to enter the passcode after which they may enter the passcode using the buzzer dialpad to gain access to the building.",
                ]}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessFAQ;
