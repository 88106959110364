import React from 'react';
import { useAnalyticsEventTracker } from '../../utils/analytics';
import LoomEmbed from '../ui/LoomEmbed';

const BusinessHero = () => {
  const eventTracker = useAnalyticsEventTracker('BusinessHero');
  return (
    <>
      <div class="mx-auto flex max-w-screen-xl flex-row justify-center px-4 pt-8 pb-2 text-center md:pt-16 md:pb-8 lg:px-12 lg:pb-8">
        <div class="justify-center text-center lg:flex lg:w-2/3 lg:flex-grow lg:flex-col">
          <h1 class="mb-4 text-center font-syne-bold text-4xl leading-none tracking-tight text-black sm:mb-8 md:text-5xl lg:text-6xl">
            {'Buzzer Check-in, Simplified'}
          </h1>
          <p class="text-md text-black-500 mb-1 font-space-grotesk-regular font-normal sm:px-16 lg:px-0 lg:text-lg xl:text-xl">
            Automate the self check-in process for rental properties with a buzzer
          </p>
          <p class="text-md text-black-500 mb-8 font-space-grotesk-regular font-normal sm:px-16 lg:px-0 lg:text-lg xl:text-xl">
            Integrate with your existing buzzer systems in minutes, no hardware installation required
          </p>

          <div class="mb-8 flex flex-col justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:mb-2 xl:mb-2">
            <a
              href="https://7i25h3fs1dk.typeform.com/to/P5KYB1he"
              // href="https://app.lowkeybuzzer.com"
              className="xl:hover:ring-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-lowkey py-2 px-5 text-center text-base font-semibold text-black ring-green-lowkey hover:border-green-lowkey hover:bg-black hover:text-green-lowkey hover:ring-2 focus:ring-1 sm:w-fit sm:px-7 sm:font-bold xl:py-3 xl:px-7"
              onClick={() => eventTracker('Lead', undefined, 'Contact')}
              // onClick={() => eventTracker('get_started_business', undefined, 'Download')}
            >
              Request Access
            </a>
            <a
              href="#features"
              className="xl:hover:ring-3 inline-flex w-full items-center justify-center rounded-md border border-transparent border-green-lowkey bg-black py-2 px-5 text-center text-base font-semibold text-white ring-green-lowkey hover:border-green-lowkey hover:ring-2 focus:ring-1 sm:w-fit sm:px-7 xl:py-3 xl:px-7"
              onClick={() => eventTracker('learn_more_business', undefined, 'ClickButton')}
            >
              Learn More
              <svg
                class="ml-2 -mr-1 h-5 w-5 rotate-90"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
            {/* <form
              className="sm:flex flex-col sm:flex-row sm:space-x-2 sm:space-y-0 space-y-2 justify-center items-center "
              action="https://getform.io/f/09afa189-3d50-439d-8c0b-333440f96f21"
              method="POST"
            >
              <input
                required
                className="inline-flex w-full sm:w-60 xl:w-72 justify-center items-center py-2 xl:py-3 px-5 text-base font-medium text-center rounded-md border-gray-300 border ring-green-lowkey hover:ring-2 hover:border-green-lowkey focus:ring-1 bg-gray-50 focus:ring-green-lowkey focus:border-green-lowkey placeholder:text-gray-400"
                type="email"
                name="email"
                placeholder="erlich@piedpiper.com"
              />
              <input
                type="hidden"
                name="_gotcha"
                style={{
                  display: 'none !important',
                }}
              />
              <button
                onClick={() => eventTracker('Lead')}
                className="inline-flex w-full sm:w-fit justify-center items-center py-2 xl:py-3 px-5 xl:px-7 text-base font-semibold text-center rounded-md border border-transparent focus:ring-1 bg-black text-white border-green-lowkey hover:ring-2 xl:hover:ring-3 hover:border-green-lowkey ring-green-lowkey"
                type="submit"
              >
                Request Access
              </button>
            </form> */}
          </div>

          {/*
          
          <div class="flex flex-col mb-8 lg:mb-8 space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
            <div className="sm:flex flex-col sm:flex-row sm:space-x-2 sm:space-y-0 space-y-2 justify-center items-center">
              {/* <input required className='inline-flex w-full sm:w-60 xl:w-72 justify-center items-center py-2 xl:py-3 px-5 text-base font-medium text-center rounded-md border-gray-300 border ring-green-lowkey hover:ring-2 hover:border-green-lowkey focus:ring-1 bg-gray-50 focus:ring-green-lowkey focus:border-green-lowkey placeholder:text-gray-400' type="email" name="email" placeholder="erlich@piedpiper.com" />
                            <input type="hidden" name="_gotcha" style={{
                                display: 'none !important',
                            }} /> /}
              <a
                href="https://calendly.com/alexrushing/lowkey-introductory-call"
                // href="https://calendly.com/tygi/ty-lowkey-introductory-call"
                onClick={() => eventTracker('Lead', undefined, undefined, 'Contact')}
                className="inline-flex w-full sm:w-fit justify-center items-center py-2 xl:py-3 px-5 xl:px-7 text-base font-semibold text-center rounded-md border border-transparent focus:ring-1 bg-black text-white border-green-lowkey hover:ring-2 xl:hover:ring-3 hover:border-green-lowkey ring-green-lowkey"
                type="submit"
              >
                Schedule Demo
                <svg
                  class="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          */}
        </div>
      </div>
      <div>
        <div class="mx-auto px-4 pb-4 sm:flex sm:flex-row sm:justify-center">
          <LoomEmbed videoId="25e27ecdac9e4e36ae9344e1f8436e1a" className="z-10 max-w-4xl rounded-lg" />
        </div>
      </div>
    </>
  );
};

export default BusinessHero;
