import React from 'react';
import Footer from './components/sections/Footer';

const Privacy = () => {
    return (
        <>
            <section className="text-left text-slate-300 py-8 px-8 bg-white">
                <div name="termly-embed" data-id="d8ddbd27-630a-42dc-9d53-8035e1d2e2e7" data-type="iframe"></div>
                <script type="text/javascript">{function (d, s, id) {
                    var js, tjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = "https://app.termly.io/embed-policy.min.js";
                    tjs.parentNode.insertBefore(js, tjs);
                }(document, 'script', 'termly-jssdk')};
                </script>
            </section>
            <Footer />
        </>
    );
};

export default Privacy;
