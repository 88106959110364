import React from 'react';
import ResponsiveHeader from './components/ResponsiveHeader';
import BuzzInInstructions from './components/sections/BuzzInInstructions';
import DownloadFromShare from './components/sections/DownloadFromShare';
import SocialMedia from './components/sections/SocialMedia';
import Footer from './components/sections/Footer';

const ShareApp = () => {
  return (
    <div>
      <ResponsiveHeader title="LOWKEY" useAbsoluteLinkPaths={true} />
      <main class="pt-16">
        <section>
          <BuzzInInstructions />
        </section>
        <section className="pt-4">
          <DownloadFromShare />
        </section>
        <SocialMedia />
        <Footer />
      </main>
    </div>
  );
};

export default ShareApp;
