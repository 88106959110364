import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div class="flex py-8 pb-12">
      <div class="mx-auto flex flex-col items-center space-y-5 font-space-grotesk-medium text-xs text-slate-500 sm:text-sm">
        <p>© Lowkey {currentYear}. All Rights Reserved.</p>
        <p>Made with ❤️ in Seattle 🌧️</p>
        <div class="grid grid-cols-3 place-items-center justify-center gap-5 sm:space-x-5 md:flex md:flex-row">
          <a href="/acceptable-use" class="hover:text-green-lowkey">
            <p>Acceptable Use</p>
          </a>
          <a href="/privacy" class="hover:text-green-lowkey">
            <p>Privacy Policy</p>
          </a>
          <a href="/terms" class="hover:text-green-lowkey">
            <p>Terms Of Service</p>
          </a>
          <a href="/disclaimer" class="hover:text-green-lowkey">
            <p>Disclaimer</p>
          </a>
          <a href="/cookies" class="hover:text-green-lowkey">
            <p>Cookie Policy</p>
          </a>
          <a href="mailto:support@lowkeybuzzer.com" class="hover:text-green-lowkey">
            <p>Contact</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
