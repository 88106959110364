import React from 'react';

const FeatureDescription = ({
  title,
  description,
  secondaryDescription,
  imageSrc,
  imageAlt,
  customFontSize,
  theme = 'dark',
  imageStyle = 'h-10 sm:h-9',
}) => {
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';
  const secondaryTextColor = theme !== 'light' ? 'text-white' : 'text-black';

  return (
    <div className="flex flex-col items-center text-center sm:max-w-xs lg:max-w-sm">
      <div className="pb-5 sm:pb-7">
        {imageSrc ? <img loading="lazy" src={imageSrc} alt={imageAlt} className={`object-fill ${imageStyle}`} /> : null}
      </div>
      <h3 className={`${textColor} pb-2 font-space-grotesk-semi-bold text-2xl lg:text-3xl`}>{title}</h3>
      <div className="flex flex-col space-y-6">
        <p className={`${textColor} text-md font-space-grotesk-regular`}>{description}</p>
        {secondaryDescription && (
          <p className={`${secondaryTextColor} text-md font-space-grotesk-regular`}>{secondaryDescription}</p>
        )}
      </div>
    </div>
  );
};

export default FeatureDescription;
