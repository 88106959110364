import React from 'react';
import { useLocation } from 'react-router-dom';
import FeatureDescription from '../core/FeatureDescription';
import Buzzer from '../../img/buzzer.png';
import NumpadBuzzer from '../../img/numpad_buzzer.png';
import BuzzerOpenDoor from '../../img/buzzer_open_door.png';
import SectionH1 from '../heading/SectionH1';
import GreenPolygon1 from '../../img/polygon_green_1.svg';
import GreenPolygon2 from '../../img/polygon_green_2.svg';
import GreenPolygon3 from '../../img/polygon_green_3.svg';
import GreenPolygon4 from '../../img/polygon_green_4.svg';

const BuzzInInstructions = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const p = searchParams.get('p');
  const v = searchParams.get('v');
  const saleTerm = searchParams.get('sale');

  return (
    <>
      <div
        className={`px-10 ${saleTerm ? 'pt-16' : 'pt-6'} sm:pb-2 ${saleTerm ? 'sm:pt-16' : 'sm:pt-8'} md:pb-10 ${
          saleTerm ? 'md:pt-28' : 'md:pt-16'
        }`}
      >
        <div class="flex flex-col items-center pb-0 pt-1 text-center sm:pb-8">
          <SectionH1 title={'Buzz In Instructions'} />
          <div class="max-w-4xl space-y-3 text-center font-space-grotesk-regular text-white">
            <p>You've been granted access to buzz yourself in with Lowkey</p>
            <p>Read the instrutions below to learn how to buzz yourself in</p>
          </div>
        </div>
      </div>

      <div className="px-10 pb-8 sm:pb-12 md:pb-20">
        <div class="sm:flex sm:flex-row sm:justify-center sm:pt-5">
          <div class="flex max-w-7xl flex-col justify-center space-y-16 px-2 pt-16 sm:flex-row sm:space-x-8 sm:space-y-0 sm:px-0 sm:py-0 lg:space-x-12">
            <FeatureDescription
              customFontSize={'text-2xl lg:text-3xl'}
              title={'1. Dial the unit from the buzzer'}
              description={'Dial the unit you are visiting from the buzzer'}
              secondaryDescription={"Dial the unit code or use the buzzer directory to find the resident's name"}
              imageSrc={Buzzer}
              imageAlt={'building icon'}
              imageStyle="h-80 sm:h-68 lg:h-80 rounded-3xl"
            />
            {p ? (
              <FeatureDescription
                customFontSize={'text-2xl lg:text-3xl'}
                title={
                  v ? (
                    <div>
                      2. Provide your access code: <span className="text-green-lowkey">{p}</span>
                    </div>
                  ) : (
                    <div>
                      2. Enter your access code: <span className="text-green-lowkey">{p}</span>
                    </div>
                  )
                }
                description={
                  v
                    ? 'After dialing the unit, you will be prompted to provide an access code'
                    : 'After dialing the unit, you will be prompted to enter an access code'
                }
                secondaryDescription={
                  v
                    ? `Say the access code ${p} aloud into the buzzer or enter the access code using the buzzer's number pad`
                    : `Use the number pad on the buzzer to enter the access code: ${p}`
                }
                imageSrc={NumpadBuzzer}
                imageAlt={'building icon'}
                imageStyle="h-80 sm:h-68 lg:h-80 rounded-3xl"
              />
            ) : null}
            <FeatureDescription
              customFontSize={'text-2xl lg:text-3xl'}
              title={`${p ? '3' : '2'}. Get buzzed in`}
              description={
                "Lowkey will buzz you in and send a push notification to the resident to let them know you've arrived"
              }
              imageSrc={BuzzerOpenDoor}
              imageAlt={'lock icon'}
              imageStyle="h-80 sm:h-68 lg:h-80 rounded-3xl"
            />
          </div>
        </div>
      </div>

      <div class="relative w-full justify-center overflow-clip sm:invisible">
        <div class="opacity-60">
          <img
            loading="lazy"
            class="absolute right-0 top-96 -z-10 h-36 sm:invisible"
            src={GreenPolygon1}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute left-10 -bottom-[920px] -z-10 h-28 opacity-80 sm:invisible"
            src={GreenPolygon1}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute right-4 -bottom-[1180px] -z-10 h-24 opacity-30 sm:invisible"
            src={GreenPolygon2}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute left-4 -bottom-[1680px] -z-10 h-20 opacity-30 sm:invisible"
            src={GreenPolygon3}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute left-12 bottom-20 -z-10 h-20 sm:left-52 sm:bottom-52 sm:h-24 sm:invisible lg:left-80 lg:bottom-56 xl:left-96"
            src={GreenPolygon2}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute -right-12 -bottom-32 -z-10 h-28 opacity-40 sm:-left-60 sm:-bottom-32 sm:invisible"
            src={GreenPolygon3}
            alt="green polygon"
          />
          <img
            loading="lazy"
            class="absolute left-0 -bottom-96 -z-10 h-32 sm:invisible"
            src={GreenPolygon4}
            alt="green polygon"
          />
        </div>
      </div>
    </>
  );
};

export default BuzzInInstructions;
