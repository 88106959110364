import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import Building from '../../img/building.svg';
import Phone from '../../img/phone.svg';
import LowkeyIcon from '../../img/lowkey_icon.svg';

const SetupProcess = ({ theme = 'dark' }) => {
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';

  return (
    <div class="px-10">
      <div class="flex flex-col items-center pb-5 pt-1 sm:pb-8">
        <SectionH2 title={'Setup Process'} theme={theme} />
        <div class={`${textColor} max-w-4xl space-y-3 text-center font-space-grotesk-regular`}>
          <p>
            Lowkey provides a virtual phone number which will be used to interface securely with your building's
            existing buzzer system.
          </p>
          <p>Get started with Lowkey today in three easy steps.</p>
        </div>
      </div>

      <div class="sm:flex sm:flex-row sm:justify-center sm:pt-5">
        <div class="flex max-w-7xl flex-col justify-center space-y-16 px-8 pt-16 sm:flex-row sm:space-x-8 sm:space-y-0 sm:px-0 sm:py-0">
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'1. Get a virtual number'}
            description={`Add a unit in the Lowkey app to receive a virtual number associated with that unit.`}
            imageSrc={Phone}
            imageAlt={'phone icon'}
            theme={theme}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'2. Send to your property manager'}
            description={`Send the virtual number to the building's property manager to have the buzzer dial this number rather than your personal phone.`}
            imageSrc={Building}
            imageAlt={'building icon'}
            theme={theme}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'3. Start using Lowkey'}
            description={'Once the buzzer is configured, guests may use Lowkey to buzz themselves in to your building.'}
            imageSrc={LowkeyIcon}
            imageAlt={'lock icon'}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default SetupProcess;
