import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
const TRACKING_ID = 'G-E24KQPS9NB'; //"UA-165871209-1";
ReactGA.initialize(TRACKING_ID);

const FB_PIXEL_ID = '1211509429775635';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(FB_PIXEL_ID, null, options);

const TIKTOK_PIXEL_ID = 'CKQQ6ERC77U3M477TOA0';
const tiktokOptions = {
  debug: false, // enable logs
};
TiktokPixel.init(TIKTOK_PIXEL_ID, tiktokOptions);

export const useAnalyticsEventTracker = (category = 'Unset category') => {
  const eventTracker = (action = 'unset action', label = 'unset label', tiktok_event_name) => {
    console.log('GA event:', category, ':', action, ':', label);
    ReactGA.event({ category, action, label });
    ReactPixel.trackCustom(action, { category: category });
    TiktokPixel.track(action, { category, action, label });
    if (tiktok_event_name) {
      TiktokPixel.track(tiktok_event_name, { category, action, label });
    } else {
      TiktokPixel.track(action, { category, action, label });
    }
  };
  return eventTracker;
};
