import React from 'react';
import ResponsiveHeader from './components/ResponsiveHeader';
import AffiliateHero from './components/sections/AffiliateHero';
import AffiliateDetails from './components/sections/AffiliateDetails';
import AffiliatePayoutCalculator from './components/sections/AffiliatePayoutCalculator';
import AffiliateSignup from './components/sections/AffiliateSignup';
import SocialMedia from './components/sections/SocialMedia';
import Footer from './components/sections/Footer';
import Section from './components/Section';

const Affiliates = () => {
  return (
    <div>
      <ResponsiveHeader title="LOWKEY" useAbsoluteLinkPaths={true} hideNav />
      <main class="pt-16">
        <section>
          <AffiliateHero />
        </section>
        <Section id="calculator">
          <AffiliatePayoutCalculator />
        </Section>
        <Section id="details">
          <AffiliateDetails />
        </Section>
        <Section id="signup">
          <AffiliateSignup />
        </Section>
        <SocialMedia />
        <Footer />
      </main>
    </div>
  );
};

export default Affiliates;
