import React from 'react';
import BusinessHero from './components/sections/BusinessHero';
import ResponsiveHeaderBusiness from './components/ResponsiveHeaderBusiness';
import Footer from './components/sections/Footer';
import Team from './components/sections/Team';
import Section from './components/Section';
import SocialMedia from './components/sections/SocialMedia';
import BusinessFeatures from './components/sections/BusinessFeatures';
import BusinessFAQ from './components/sections/BusinessFAQ';
import BusinessCompaniesList from './components/sections/BusinessCompaniesList';
import BusinessSetupProcess from './components/sections/BusinessSetupProcess';
import BusinessHowItWorks from './components/sections/BusinessHowItWorks';
import BusinessPricing from './components/sections/BusinessPricing';

const Business = () => {
  return (
    <div>
      <ResponsiveHeaderBusiness title="LOWKEY" subtitle="BUZZER SELF CHECK-IN" />
      <div>
        <main class="overflow-hidden pt-16">
          <Section id={'hero'} topPadding="pt-2" bottomPadding="pt-12">
            <BusinessHero />
          </Section>

          <BusinessCompaniesList />

          <Section id={'features'} topPadding="pt-16">
            <BusinessFeatures theme="light" />
          </Section>
          <Section id={'setupProcess'}>
            <BusinessSetupProcess theme="light" />
          </Section>
          <Section id={'howItWorks'}>
            <BusinessHowItWorks theme="light" />
          </Section>
          <Section id={'team'}>
            <Team theme="light" />
          </Section>
          <Section id={'pricing'}>
            <BusinessPricing theme="light" />
          </Section>
          <Section id={'faq'}>
            <BusinessFAQ theme="light" />
          </Section>
          <SocialMedia theme="light" />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Business;
