import React from 'react';
import SectionH2 from '../heading/SectionH2';
import Ghost from '../../img/ghost.svg';
import Amazon from '../../img/amazon.svg';
import Adobe from '../../img/adobe.svg';
import UCLA from '../../img/ucla.svg';
import GeorgiaTech from '../../img/georgia_tech.svg';
import GhostDark from '../../img/ghost_dark.svg';
import AmazonDark from '../../img/amazon_dark.svg';
import AdobeDark from '../../img/adobe_dark.svg';
import UCLADark from '../../img/ucla_dark.svg';
import GeorgiaTechDark from '../../img/georgia_tech_dark.svg';

const Team = ({ theme = 'dark' }) => {
  const textColor = theme !== 'light' ? 'text-white' : 'text-black';
  const isLightTheme = theme === 'light';

  return (
    <div className="relative">
      <div
        style={{ height: 1200, width: 1200, left: -700, top: -390 }}
        class="absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 sm:invisible"
      />
      <div
        style={{ height: 1772, width: 1772, left: -1100, top: -600 }}
        class="invisible absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 sm:flex 2xl:invisible"
      />
      <div
        style={{ height: 1772, width: 1772, left: -1000, top: -600 }}
        class="invisible absolute -z-10 rounded-full bg-gradient-radial from-green-lowkey via-transparent to-transparent opacity-30 2xl:visible"
      />
      <div className="px-10">
        <div className="flex flex-row justify-center">
          <div className="flex max-w-7xl flex-col pb-5 pt-1 sm:flex-row sm:justify-center sm:pb-16">
            <div className="sm:w-1/3">
              <div className="flex flex-col items-center sm:items-start">
                <SectionH2 customAlignment={'text-center sm:text-left'} title={'Our Team'} theme={theme} />
              </div>
            </div>
            <div
              className={`text-center sm:flex sm:text-left ${textColor} flex-col justify-center space-y-4 sm:w-2/3 sm:flex-none sm:gap-4 sm:space-y-0`}
            >
              <p>
                We’re a team of engineers, product designers, and automation enthusiasts who believe in the power of
                technology to simplify our lives.
              </p>
              <p>Our team has experience building large scale applications at the world’s top tech companies.</p>
              <div className="space-y-8 pt-3">
                <div className="flex flex-row items-center justify-around">
                  <img
                    loading="lazy"
                    src={isLightTheme ? GhostDark : Ghost}
                    className="h-11 object-fill"
                    alt="Snap logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? AmazonDark : Amazon}
                    className="h-6 object-fill"
                    alt="Amazon logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? AdobeDark : Adobe}
                    className="h-10 object-fill"
                    alt="Adobe logo"
                  />
                </div>
                <div className="flex flex-row items-center justify-evenly">
                  <img
                    loading="lazy"
                    src={isLightTheme ? UCLADark : UCLA}
                    className="h-6 object-fill"
                    alt="UCLA logo"
                  />
                  <img
                    loading="lazy"
                    src={isLightTheme ? GeorgiaTechDark : GeorgiaTech}
                    className="h-7 object-fill"
                    alt="Georgia Tech logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
