import React from 'react';
import SectionH2 from '../heading/SectionH2';
import { useAnalyticsEventTracker } from '../../utils/analytics';

const AffiliateDetails = () => {
  const eventTracker = useAnalyticsEventTracker('AffiliateDetails');

  return (
    <div id="details" className="px-10">
      <div className="flex flex-col items-center pb-0 pt-1 sm:pb-8">
        <SectionH2 title={'Affiliate Program Details'} />
        <div className="max-w-4xl space-y-3 text-center font-space-grotesk-regular text-white">
          <p>
            Earn passive income by promoting the{' '}
            <a href="/" className="underline">
              Lowkey - Smart Buzzer
            </a>{' '}
            app to your audience.
          </p>
          <p>Here's what you need to know about our affiliate program:</p>
        </div>
      </div>

      <div className="sm:flex sm:flex-row sm:justify-center sm:pt-5">
        <div className="flex max-w-5xl flex-col justify-center space-y-16 px-2 pt-16 text-center sm:space-y-8 sm:px-0 sm:py-0 sm:text-left">
          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">Generous Commissions</h3>
            <p className="text-gray-400">
              Earn up to 60% commissions on every referral who converts to a paid subscriber. Payouts are sent via
              PayPal, so you can easily receive your earnings.
            </p>
          </div>

          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">
              2 Months Free for Referrals
            </h3>
            <p className="text-gray-400">
              Your referrals get 2 months free on their Lowkey subscription when they sign up using your unique
              affiliate code. It's a win-win - they save money while you earn commissions!
            </p>
          </div>

          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">Easy to Get Started</h3>
            <p className="text-gray-400">
              Signing up for our affiliate program is quick and easy. There are no upfront costs or minimum sales
              required. Once approved, simply share your affiliate code and start earning on every conversion.
            </p>
          </div>

          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">Dedicated Support</h3>
            <p className="text-gray-400">
              Our affiliate support team is here to help you succeed. Get access to marketing resources, regular program
              updates, and prompt assistance whenever you need it.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-16 text-center">
        <a
          href="#signup"
          onClick={() => eventTracker('signup_navigate', undefined, 'ClickButton')}
          className="inline-flex items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800"
        >
          Learn How To Join
          <svg
            class="ml-2 -mr-1 h-5 w-5 rotate-90"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default AffiliateDetails;
