import React from 'react';
import SectionH1 from '../heading/SectionH1';
import { useAnalyticsEventTracker } from '../../utils/analytics';

const AffiliateHero = () => {
  const eventTracker = useAnalyticsEventTracker('AffiliateHero');

  return (
    <div class="relative overflow-clip">
      <div className="mx-auto flex max-w-screen-xl flex-row px-4 pt-10 pb-2 text-center md:pt-16 md:pb-8 lg:px-12 lg:pb-12">
        <div class="text-center lg:flex lg:w-full lg:flex-grow lg:flex-col lg:justify-center">
          <div className="mb-6 sm:mb-0">
            <SectionH1 title="Earn up to 60% Commissions" />
          </div>
          <p class="text-md mb-1 font-space-grotesk-regular font-normal text-gray-400 sm:mb-0 sm:px-16 lg:px-0 lg:text-lg xl:text-xl">
            Promote{' '}
            <a href="/" className="underline">
              Lowkey - Smart Buzzer
            </a>{' '}
            and earn passive income on every referral
          </p>
          <p class="text-md mb-8 font-space-grotesk-regular font-normal text-gray-400 sm:px-16 md:mb-12 lg:px-0 lg:text-lg xl:mb-16 xl:text-xl">
            Join our affiliate program and start earning today - no upfront costs or minimum sales required
          </p>

          <div class="mb-0 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 lg:mb-8">
            <a
              href="#calculator"
              onClick={() => eventTracker('learn_more_navigate', undefined, 'ClickButton')}
              class="inline-flex items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800"
            >
              View Payout Calculator
              <svg
                class="ml-2 -mr-1 h-5 w-5 rotate-90"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="#details"
              onClick={() => eventTracker('signup_navigate', undefined, 'ClickButton')}
              class="bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 inline-flex items-center justify-center rounded-lg bg-gray-900 py-3 px-5 text-center text-base font-medium text-white focus:ring-4"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateHero;
