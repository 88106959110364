import React, { useState, useEffect, useRef } from 'react';
import SectionH2 from '../heading/SectionH2';
import FiveStars from '../../img/five_stars.svg';
import { CountUp } from 'use-count-up';

const Testimonials = () => {
  const [countStart, setCountStart] = useState(false);
  const countUpRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCountStart(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }, // Adjust threshold as needed
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => observer.disconnect();
  }, []);
  return (
    <div class="">
      <div class="flex flex-col items-center px-10 pb-5 pt-1">
        <SectionH2 title={'What People Are Saying'} />
        <div
          ref={countUpRef}
          className="text-md mb-8 text-center font-space-grotesk-regular font-normal text-white md:mb-12 xl:mb-16"
        >
          Join the community of residents who have buzzed in
          <span className="mx-1.5 inline font-bold italic text-green-lowkey">
            {countStart && <CountUp isCounting thousandsSeparator={','} end={229948} duration={2} />}+
          </span>
          guests with Lowkey
        </div>
      </div>

      <div class="flex flex-row justify-start overflow-x-auto px-4 sm:pt-5 lg:justify-center">
        <div class="relative">
          <div class="absolute bottom-0 h-36 w-full bg-gradient-to-t from-black to-transparent"></div>
          <div class="flex min-w-fit flex-row space-x-4">
            <div class="-z-10 flex w-72 flex-col rounded-xl bg-blue-400">
              <div class="flex flex-col items-start space-y-3 p-5">
                <div class="flex flex-row items-center space-x-3 font-space-grotesk-medium text-sm">
                  {/* <div class="bg-white rounded-full w-10 h-10"></div> */}
                  <div class="flex flex-col space-y-0">
                    <p class="font-space-grotesk-semi-bold">Nina D</p>
                    <p class="font-space-grotesk-regular">Seattle, WA</p>
                  </div>
                </div>

                <div>
                  <img loading="lazy" src={FiveStars} class="my-2 h-5 object-contain" alt={'five stars'} />
                  <p class=" font-space-grotesk-semi-bold">"Great app, has made my life easier"</p>
                </div>
                <p>
                  This app is awesome if you live in an apartment complex with a buzzer. Super convenient for food
                  delivery and having large groups of friends over. I hosted a party and it was great not having to pick
                  up the phone to let people in. I also like the feature of being able to put a passcode for different
                  activities- makes me feel much more secure.
                </p>
              </div>
            </div>
            <div class="-z-10 flex w-72 flex-col rounded-xl bg-blue-400">
              <div class="flex flex-col items-start space-y-3 p-5">
                <div class="flex flex-row items-center space-x-3 font-space-grotesk-medium text-sm">
                  {/* <div class="bg-white rounded-full w-10 h-10"></div> */}
                  <div class="flex flex-col space-y-0">
                    <p class="font-space-grotesk-semi-bold">Andre W</p>
                    <p class="font-space-grotesk-regular">Atlanta, GA</p>
                  </div>
                </div>
                <div>
                  <img loading="lazy" src={FiveStars} class="my-2 h-5 object-contain" alt={'five stars'} />
                  <p class=" font-space-grotesk-semi-bold">"I love this app it’s genius!"</p>
                </div>
                <p>
                  I'm able to easily share access to my apartment buzzer with my roomate through the app which is great
                  in case he's not around. We also use it to schedule access for when cleaners come over once a month
                  while we're working.
                </p>
                <p>
                  Also love being able to get the push notifications on my apple watch so I know when my friends arrive.
                </p>
              </div>
            </div>
            <div class="-z-10 flex w-72 flex-col rounded-xl bg-blue-400">
              <div class="flex flex-col items-start space-y-3 p-5">
                <div class="flex flex-row items-center space-x-3 font-space-grotesk-medium text-sm">
                  {/* <div class="bg-white rounded-full w-10 h-10"></div> */}
                  <div class="flex flex-col space-y-0">
                    <p class="font-space-grotesk-semi-bold">Ethan D</p>
                    <p class="font-space-grotesk-regular">Los Angeles, CA</p>
                  </div>
                </div>

                <div>
                  <img loading="lazy" src={FiveStars} class="my-2 h-5 object-contain" alt={'five stars'} />
                  <p class="font-space-grotesk-semi-bold">"Super convenient, especially for food delivery"</p>
                </div>
                <p>
                  Love this app for whenever I order UberEats or DoorDash. My apartment is super annoying to go down to
                  the front door and I can't answer calls while I'm working so this has made ordering food way way
                  easier.
                </p>
                <p>
                  Great when I have friends over for bigger get togethers too so I don't have to keep checking my phone
                  to buzz them in.
                </p>
              </div>
            </div>
            <div class="-z-10 flex w-72 flex-col rounded-xl bg-blue-400">
              <div class="flex flex-col items-start space-y-3 p-5">
                <div class="flex flex-row items-center space-x-3 text-sm">
                  {/* <div class="bg-white rounded-full w-10 h-10"></div> */}
                  <div class="flex flex-col space-y-0">
                    <p class="font-space-grotesk-semi-bold">Morganne K</p>
                    <p class="font-space-grotesk-regular">Tampa, FL</p>
                  </div>
                </div>

                <div>
                  <img loading="lazy" src={FiveStars} class="my-2 h-5 object-contain" alt={'five stars'} />
                  <p class=" font-space-grotesk-semi-bold">"Security features make me feel safer"</p>
                </div>
                <p>
                  Being able to check the activity history of who came in on the app is really nice and gives me peace
                  of mind living alone in an apartment.
                </p>
                <p>
                  The share with roommates features is also super convenient, I share with my other roommate who works
                  totally different hours than me so it's nice to be able to answer the door from any phone and not
                  accidentally wake her up if I'm ordering food or having people over.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="z-50 flex flex-row justify-center pt-10 sm:pt-20">
        <a
          href="#pricing"
          onClick={() => {}}
          class="inline-flex items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800"
        >
          View pricing
          <svg
            class="ml-2 -mr-1 h-5 w-5 rotate-90"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Testimonials;
