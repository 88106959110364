import React from 'react';
import PropTypes from 'prop-types';

const LoomEmbed = ({ videoId, className }) => {
  const embedUrl = `https://www.loom.com/embed/${videoId}?autoplay=0`;

  const defaultClasses = 'w-full h-[300px] md:h-[500px]';
  const combinedClasses = `${defaultClasses} ${className}`;

  return (
    <iframe
      src={embedUrl}
      title={'Lowkey Dashboard Overview'}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      frameborder=""
      allowfullscreen
      className={combinedClasses}
    />
  );
};

LoomEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LoomEmbed.defaultProps = {
  className: '',
};

export default LoomEmbed;
