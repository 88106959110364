import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Home';
import Privacy from './Privacy';
import Terms from './Terms';
import DownloadPage from './DownloadPage';
import Cookies from './Cookies';
import AcceptableUse from './AcceptableUse';
import Disclaimer from './Disclaimer';
import Business from './Business';
import 'flowbite';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import ShareApp from './ShareApp';
import Affiliates from './Affiliates';

const TRACKING_ID = 'G-E24KQPS9NB'; //"UA-165871209-1";
ReactGA.initialize(TRACKING_ID);

const FB_PIXEL_ID = '1211509429775635';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(FB_PIXEL_ID, null, options);

const TIKTOK_PIXEL_ID = 'CKQQ6ERC77U3M477TOA0';
const tiktokOptions = {
  debug: false, // enable logs
};
TiktokPixel.init(TIKTOK_PIXEL_ID, tiktokOptions);

const App = () => {
  const isShareSubdomain = window.location.hostname.startsWith('share.');

  useEffect(() => {
    ReactGA.pageview(window.location.hostname + window.location.pathname + window.location.search);
    ReactPixel.pageView();
    TiktokPixel.pageView();
  }, []);

  if (isShareSubdomain) {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Share Buzzer Access Instructions</title>
                  <meta
                    name="description"
                    content="Share buzzer access instructions to securely buzz in your guests."
                  />
                </Helmet>
                <ShareApp />
              </>
            }
          />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Smart Buzzer For Apartments Or Condos. Forward calls to multiple phones.</title>
                  <meta
                    name="description"
                    content="Control your apartment or condo buzzer from your phone! No installation required, works with all buzzers which dial a mobile phone number."
                  />
                </Helmet>
                <Home />
              </>
            }
          />

          {/* Business use case routes */}
          <Route
            path="/doordash"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Automatically Buzz in DoorDash Deliveries</title>
                  <meta
                    name="description"
                    content="Lowkey ensures your DoorDash order arrives hot. Automatic buzzer entry means no more cold food or missed deliveries."
                  />
                </Helmet>
                <Home channel="doordash" />
              </>
            }
          />
          <Route
            path="/instacart"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Automatically Buzz in Instacart Shoppers</title>
                  <meta
                    name="description"
                    content="Let Lowkey automatically buzz in your Instacart shopper. No more rushing to the phone or missing groceries."
                  />
                </Helmet>
                <Home channel="instacart" />
              </>
            }
          />
          <Route
            path="/smb"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Streamline Client Buzzer Access For Your Business</title>
                  <meta
                    name="description"
                    content="Stop wasting time buzzing in clients. Lowkey automates buzzer entry for your business, saving you time and hassle."
                  />
                </Helmet>
                <Home channel="buzz_in_clients" />
              </>
            }
          />
          <Route
            path="/caregivers"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Simplify Access for Your Elderly Parents' Caregivers</title>
                  <meta
                    name="description"
                    content="Provide secure, convenient access for healthcare workers and assistants caring for your elderly parents in their condo or apartment."
                  />
                </Helmet>
                <Home channel="caregivers" />
              </>
            }
          />
          <Route
            path="/delivery"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Say Goodbye To Missed Deliveries</title>
                  <meta
                    name="description"
                    content="Automatically buzz in your deliveries without answering your phone"
                  />
                </Helmet>
                <Home channel="delivery" />
              </>
            }
          />
          <Route
            path="/ubereats"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Automatically Buzz in UberEats Deliveries</title>
                  <meta
                    name="description"
                    content="Lowkey ensures your UberEats order arrives hot. Automatic buzzer entry means no more cold food or missed deliveries."
                  />
                </Helmet>
                <Home channel="ubereats" />
              </>
            }
          />
          <Route
            path="/business"
            element={
              <>
                <Helmet>
                  <title>Lowkey for Businesses - Smart Buzzer For Apartments Or Condos</title>
                  <meta
                    name="description"
                    content="Discover how Lowkey can transform your business operations with smart, secure access control."
                  />
                </Helmet>
                <Business />
              </>
            }
          />
          <Route
            path="/affiliates"
            element={
              <>
                <Helmet>
                  <title>Lowkey Affiliate Program - Partner With Us</title>
                  <meta
                    name="description"
                    content="Join Lowkey's affiliate program and earn by promoting our smart buzzer solutions."
                  />
                </Helmet>
                <Affiliates />
              </>
            }
          />

          {/* Other routes */}
          <Route
            path="/privacy"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Privacy Policy</title>
                  <meta name="description" content="Read our privacy policy to understand how we protect your data." />
                </Helmet>
                <Privacy />
              </>
            }
          />
          <Route
            path="/terms"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Terms of Service</title>
                  <meta
                    name="description"
                    content="Review our terms of service for using Lowkey's smart buzzer system."
                  />
                </Helmet>
                <Terms />
              </>
            }
          />
          <Route
            path="/cookies"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Cookie Policy</title>
                  <meta name="description" content="Learn about how we use cookies on our website." />
                </Helmet>
                <Cookies />
              </>
            }
          />
          <Route
            path="/acceptable-use"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Acceptable Use Policy</title>
                  <meta
                    name="description"
                    content="Understand our guidelines for acceptable use of Lowkey's services."
                  />
                </Helmet>
                <AcceptableUse />
              </>
            }
          />
          <Route
            path="/disclaimer"
            element={
              <>
                <Helmet>
                  <title>Lowkey - Disclaimer</title>
                  <meta
                    name="description"
                    content="Read important disclaimers regarding the use of Lowkey's smart buzzer system."
                  />
                </Helmet>
                <Disclaimer />
              </>
            }
          />
          <Route
            path="/download"
            element={
              <>
                <Helmet>
                  <title>Download Lowkey - Get Started with Smart Access</title>
                  <meta
                    name="description"
                    content="Download the Lowkey app and start controlling your buzzer from your phone today!"
                  />
                </Helmet>
                <DownloadPage />
              </>
            }
          />
        </Routes>
      </Router>
    );
  }
};

export default App;
