import React from 'react';
import Footer from './components/sections/Footer';

const Terms = () => {
  return (
    <>
      <section className="bg-white py-8 px-8 text-left text-slate-300">
        <div name="termly-embed" data-id="a848acd4-dce5-4803-82dc-a99eb4f0744f" data-type="iframe"></div>
        <script type="text/javascript">
          {(function (d, s, id) {
            var js,
              tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://app.termly.io/embed-policy.min.js';
            tjs.parentNode.insertBefore(js, tjs);
          })(document, 'script', 'termly-jssdk')}
        </script>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
