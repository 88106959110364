import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import lowkeyIphone from '../../img/lowkey_iphone_business.png';
import Lock from '../../img/lock.svg';
import ClockGreen from '../../img/clock_green.svg';
import Unlock from '../../img/unlock.svg';
import Share from '../../img/share.svg';
import Phone from '../../img/phone.svg';
import Building from '../../img/building.svg';
import YellowPolygon from '../../img/polygon_yellow.svg';
import GreenPolygon1 from '../../img/polygon_green_1.svg';
import GreenPolygon2 from '../../img/polygon_green_2.svg';
import GreenPolygon3 from '../../img/polygon_green_3.svg';
import GreenPolygon4 from '../../img/polygon_green_4.svg';
// import Spline from '@splinetool/react-spline';

const BusinessFeatures = ({ theme = 'dark' }) => {
  return (
    <div class="relative">
      {/*
      <div class="absolute sm:hidden top-10 -z-50 h-1/3 w-full overflow-visible">
        <Spline scene="https://prod.spline.design/uoL01wCAECh-m46u/scene.splinecode" />
      </div>
      */}
      <div class="flex flex-row justify-center pt-1 pb-6 sm:pb-14">
        <SectionH2 title={'Features'} theme={theme} />
      </div>

      <div class="sm:flex sm:flex-row sm:justify-center sm:px-8 xl:px-20">
        <div class="flex flex-shrink flex-row justify-center">
          <div class="flex w-3/4 px-12 pb-4 sm:hidden">
            <div class="relative">
              <img class="aspect-w-9 relative" src={lowkeyIphone} alt="Lowkey App shown on iPhone" />
            </div>
          </div>
        </div>

        <div class="flex flex-shrink flex-col justify-center sm:flex-row sm:space-x-16 xl:space-x-24">
          <div class="hidden px-12 sm:flex sm:w-1/2 sm:px-0 lg:w-1/4 xl:w-72">
            <div class="relative">
              <img loading="lazy" class="aspect-w-9 relative" src={lowkeyIphone} alt="Lowkey App shown on iPhone" />
            </div>
          </div>

          <div class="relative flex flex-row justify-center sm:w-2/3">
            <div class="opacity-60">
              <img
                loading="lazy"
                class="absolute right-0 top-5 -z-10 h-32 sm:right-16 sm:-top-48 sm:h-36 xl:right-32"
                src={YellowPolygon}
                alt="yellow polygon"
              />
              <img
                loading="lazy"
                class="absolute right-24 top-96 -z-10 h-36 sm:-right-36 sm:top-0 sm:h-60"
                src={GreenPolygon1}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute left-12 bottom-20 -z-10 h-20 sm:left-52 sm:bottom-52 sm:h-24 lg:left-80 lg:bottom-56 xl:left-96"
                src={GreenPolygon2}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute -right-2 -bottom-32 -z-10 h-32 sm:-left-60 sm:-bottom-32"
                src={GreenPolygon3}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute -left-2 -bottom-96 -z-10 h-32 sm:invisible"
                src={GreenPolygon4}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="invisible absolute -z-10 sm:visible sm:right-0 sm:-bottom-80 sm:h-48"
                src={GreenPolygon4}
                alt="green polygon"
              />
            </div>
            <div class="flex flex-col justify-center space-y-16 px-8 pt-8 sm:grid sm:grid-cols-2 sm:gap-4 sm:gap-y-8 sm:space-y-0 sm:px-0 sm:py-0 sm:pt-16">
              <FeatureDescription
                title={'Guest Self Check-In'}
                description={
                  'Enable guests to buzz themselves in and stop answering your phone every time a guest has to get into the building.'
                }
                imageSrc={Unlock}
                imageAlt={'unlock icon'}
                theme={theme}
              />
              <FeatureDescription
                title={'Sync with Guesty'}
                description={
                  'Automatically sync with your Guesty bookings to generate a unique access code for each reservation prior to check-in and deactivate it following check-out.'
                }
                imageSrc={ClockGreen}
                imageAlt={'clock icon'}
                theme={theme}
              />
              <FeatureDescription
                title={'Improve Security'}
                description={
                  'Avoid security issues and costs associated with lost physical keys or FOBs and sharing of fixed building buzzer codes.'
                }
                imageSrc={Lock}
                imageAlt={'lock icon'}
                theme={theme}
              />
              <FeatureDescription
                title={'Monitor Access'}
                description={
                  'Configure alerts to receive email notifications when guests, maintanence, or cleaning services are granted or denied access to your building.'
                }
                imageSrc={Building}
                imageAlt={'phone icon'}
                theme={theme}
              />
              <FeatureDescription
                title={'Share Access with Your Team'}
                description={'Add authorized admins to your account to help manage your rentals.'}
                imageSrc={Share}
                imageAlt={'share icon'}
                theme={theme}
              />
              <FeatureDescription
                title={'Customize Callbox Greetings'}
                description={
                  'Welcome your guests with custom greetings, or disable greetings altogether for maximum discretion.'
                }
                imageSrc={Phone}
                imageAlt={'phone icon'}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessFeatures;
