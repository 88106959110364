import React from 'react';
import SectionH2 from '../heading/SectionH2';
import { useAnalyticsEventTracker } from '../../utils/analytics';

const AffiliateSignup = () => {
  const eventTracker = useAnalyticsEventTracker('AffiliateSignup');

  return (
    <div id="signup" className="px-10 text-center sm:text-left">
      <div className="flex flex-col items-center pb-0 pt-1 sm:pb-8">
        <SectionH2 title={'Join the Affiliate Program'} />
        <div className="max-w-4xl space-y-3 text-center font-space-grotesk-regular text-white">
          <p>Ready to start earning with Lowkey? Here's how to join our affiliate program:</p>
        </div>
      </div>

      <div className="sm:flex sm:flex-row sm:justify-center sm:pt-5">
        <div className="flex max-w-5xl flex-col space-y-16 px-2 pt-16 sm:space-y-8 sm:px-0 sm:py-0">
          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">
              1. Submit Your Application
            </h3>
            <p className="text-gray-400">
              Fill out our short affiliate application form. We'll review your submission and get back to you within 1-2
              business days.
            </p>
          </div>

          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">
              2. Get Your Affiliate Link
            </h3>
            <p className="text-gray-400">
              Once approved, you'll receive a unique affiliate link to share with your audience. Any signups through
              your link will be credited to your account.
            </p>
          </div>

          <div>
            <h3 className="mb-2 font-space-grotesk-semi-bold text-2xl text-white lg:text-3xl">3. Promote and Earn</h3>
            <p className="text-gray-400">
              Share your affiliate link on your website, social media, email newsletters, and more. You'll earn
              commissions on every paid conversion.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-16 text-center">
        <a
          href={`mailto:contact@lowkeybuzzer.com?subject=${encodeURIComponent(
            'Interest In Affiliate Program',
          )}&body=${encodeURIComponent(`Thanks for your interest in the Lowkey affiliate program! We'll ask you a few questions to start the application process, then schedule a follow up call if you're a good fit.

Please answer the following questions to start the application process:
          
1. How many users do you expect to refer per month?

2. Where will you be promoting Lowkey? Please provide links to your social media profiles, websites, or other platforms where you plan to promote Lowkey.

3. What is your plan to refer users? Please provide a detailed plan of how you plan to refer users to Lowkey. (Social media content, in-person, via existing client-base, etc.)

4. Are you able to accept payment via PayPal? (Yes/No)

`)}`}
          onClick={() => eventTracker('affiliate_apply', undefined, 'ClickButton')}
          className="inline-flex items-center justify-center rounded-lg border border-green-lowkey bg-green-lowkey py-3 px-5 text-center text-base font-medium text-black hover:bg-black hover:text-green-lowkey focus:ring-4 focus:ring-gray-800"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default AffiliateSignup;
