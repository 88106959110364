import React from 'react';
// import Instacart from '../../img/instacart.svg';

const BusinessCompaniesList = () => {
  return (
    <>
      <div class="flex flex-row justify-center">
        <p class="text-black-500 pb-8 font-syne-bold text-xl sm:pb-9 sm:text-2xl xl:pb-10">Best Used With</p>
      </div>
      <div class="flex flex-col overflow-x-clip">
        <div class="z-0 bg-green-lowkey shadow-lg" style={{ boxShadow: '0 0 12px 12px #41E1B0' }} />
        <div class="relative flex overflow-x-hidden bg-green-lowkey">
          <div class="flex items-center justify-center py-3 font-montserrat-semi-bold text-lg text-black sm:text-xl lg:text-2xl">
            <div class="animate-marquee whitespace-nowrap">
              <span class="mx-10">Guesty</span>
              <span class="mx-10">AirBnb</span>
              <span class="mx-10">VRBO</span>
              <span class="mx-10">HomeAway</span>
              <span class="mx-10">Booking.com</span>
              <span class="mx-10">Expedia</span>
              <span class="mx-10">Vacasa</span>
            </div>
            <div class="absolute top-3 animate-marquee2 whitespace-nowrap">
              <span class="mx-10">Guesty</span>
              <span class="mx-10">AirBnb</span>
              <span class="mx-10">VRBO</span>
              <span class="mx-10">HomeAway</span>
              <span class="mx-10">Booking.com</span>
              <span class="mx-10">Expedia</span>
              <span class="mx-10">Vacasa</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessCompaniesList;
