import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import lowkeyIphone from '../../img/lowkey_iphone_gradient_bg.png';
import Lock from '../../img/lock.svg';
import ClockGreen from '../../img/clock_green.svg';
import Unlock from '../../img/unlock.svg';
import Share from '../../img/share.svg';
import Phone from '../../img/phone.svg';
// import Heart from '../../img/heart.svg';
import YellowPolygon from '../../img/polygon_yellow.svg';
import GreenPolygon1 from '../../img/polygon_green_1.svg';
import GreenPolygon2 from '../../img/polygon_green_2.svg';
import GreenPolygon3 from '../../img/polygon_green_3.svg';
import GreenPolygon4 from '../../img/polygon_green_4.svg';
import Spline from '@splinetool/react-spline';

const Features = ({ channel }) => {
  const getFeatureDescriptions = () => {
    if (channel === 'instacart') {
      return [
        {
          title: 'Automatic Instacart Entry',
          description: 'Let your Instacart shopper in automatically, without answering your phone.',
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Share Access with Roommates',
          description: `Ensure all roommates can receive Instacart deliveries, even when you're not home.`,
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Delivery Notifications',
          description: 'Get notified when your Instacart shopper arrives and track their entry.',
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Schedule Recurring Deliveries',
          description: 'Set up automatic entry for specific days and times, such as every Tuesday from 3-6 PM.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    } else if (channel === 'doordash' || channel === 'ubereats') {
      const serviceName = channel === 'doordash' ? 'DoorDash' : 'UberEats';
      return [
        {
          title: `Seamless ${serviceName} Entry`,
          description: `Allow your ${serviceName} driver to enter without you rushing to the buzzer.`,
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Share with Roommates',
          description: `Everyone in your home can receive their ${serviceName} orders hassle-free.`,
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Real-time Arrival Alerts',
          description: `Receive notifications when your ${serviceName} driver arrives at your building.`,
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Recurring Delivery Access',
          description:
            'Schedule automatic entry for specific time slots, such as daily from 6-8 PM for dinner deliveries.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    } else if (channel === 'caregivers') {
      return [
        {
          title: 'Easy Caregiver Access',
          description: 'Provide secure entry for healthcare workers without compromising security.',
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Family Access Sharing',
          description: 'Allow family members to manage caregiver access remotely.',
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Caregiver Entry Logs',
          description: 'Keep track of when caregivers arrive and leave for peace of mind.',
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Scheduled Care Visits',
          description: 'Set up recurring access for regular caregiver visits, such as every weekday from 9 AM to 5 PM.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    } else if (channel === 'buzz_in_clients') {
      return [
        {
          title: 'Automatic Client Entry',
          description: 'Allow clients to enter your building automatically, saving you time and hassle.',
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Customizable Passcodes',
          description: 'Create unique access codes for different clients or time slots.',
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Entry Notifications',
          description: 'Receive instant alerts when clients arrive, enhancing security and preparedness.',
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Scheduled Access',
          description: 'Set up recurring entry times for regular clients, streamlining your business operations.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    } else if (channel === 'delivery') {
      return [
        {
          title: 'Automatic Delivery Entry',
          description: 'Buzz in delivery drivers automatically, ensuring your packages are always delivered.',
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Share Access with Household',
          description: "Ensure everyone in your home can receive deliveries, even when you're not there.",
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Delivery Notifications',
          description: 'Receive instant push notifications when a delivery driver arrives and track their entry.',
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Schedule Delivery Windows',
          description: 'Set up automatic entry for specific or recurring delivery time windows.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    } else {
      return [
        {
          title: 'Unlock Your Building Door',
          description: 'Let in guests without answering your phone. Create secure entry codes for visitors.',
          imageSrc: Unlock,
          imageAlt: 'unlock icon',
        },
        {
          title: 'Share Access',
          description: "Forward calls and share app access with roommates. Don't be tied to a single phone number.",
          imageSrc: Share,
          imageAlt: 'share icon',
        },
        {
          title: 'Control Security',
          description: 'Receive push notifications when guests arrive. Review access history logs at any time.',
          imageSrc: Lock,
          imageAlt: 'lock icon',
        },
        {
          title: 'Automate Home Services',
          description: 'Schedule recurring access for cleaners, dog walkers, and more at specific days and times.',
          imageSrc: ClockGreen,
          imageAlt: 'clock icon',
        },
        {
          title: 'Get a Local Phone Number',
          description: 'Receive a virtual phone number from a local area code that will work with your buzzer.',
          imageSrc: Phone,
          imageAlt: 'phone icon',
        },
      ];
    }
  };

  const featureDescriptions = getFeatureDescriptions();

  return (
    <div class="relative">
      <div class="absolute top-10 -z-50 h-1/3 w-full overflow-visible sm:hidden">
        <Spline scene="https://prod.spline.design/uoL01wCAECh-m46u/scene.splinecode" />
      </div>
      <div class="flex flex-row justify-center pt-1 sm:pb-14">
        <SectionH2 title={'Features'} />
      </div>

      <div class="sm:flex sm:flex-row sm:justify-center sm:px-8 xl:px-20">
        <div class="flex flex-shrink flex-row justify-center">
          <div class="flex w-11/12 px-12 sm:hidden">
            <div class="relative">
              <img class="aspect-w-9 relative" src={lowkeyIphone} alt="Lowkey App shown on iPhone" />
            </div>
          </div>
        </div>
        <div class="flex flex-shrink flex-col justify-center sm:flex-row sm:space-x-16 xl:space-x-24">
          <div class="hidden px-12 sm:flex sm:w-1/2 sm:px-0 lg:w-1/3 xl:w-96">
            <div class="relative">
              <img loading="lazy" class="aspect-w-9 relative" src={lowkeyIphone} alt="Lowkey App shown on iPhone" />
            </div>
          </div>

          <div class="relative flex flex-row justify-center sm:w-2/3">
            <div class="opacity-60">
              <img
                loading="lazy"
                class="absolute right-0 top-5 -z-10 h-32 sm:right-16 sm:-top-48 sm:h-36 xl:right-32"
                src={YellowPolygon}
                alt="yellow polygon"
              />
              <img
                loading="lazy"
                class="absolute right-24 top-96 -z-10 h-36 sm:-right-36 sm:top-0 sm:h-60"
                src={GreenPolygon1}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute left-10 -bottom-[920px] -z-10 h-28 opacity-80 sm:invisible"
                src={GreenPolygon1}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute right-4 -bottom-[1180px] -z-10 h-24 opacity-30 sm:invisible"
                src={GreenPolygon2}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute left-4 -bottom-[1680px] -z-10 h-20 opacity-30 sm:invisible"
                src={GreenPolygon3}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute left-12 bottom-20 -z-10 h-20 sm:left-52 sm:bottom-52 sm:h-24 lg:left-80 lg:bottom-56 xl:left-96"
                src={GreenPolygon2}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute -right-2 -bottom-32 -z-10 h-32 sm:-left-60 sm:-bottom-32"
                src={GreenPolygon3}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="absolute -left-2 -bottom-96 -z-10 h-32 sm:invisible"
                src={GreenPolygon4}
                alt="green polygon"
              />
              <img
                loading="lazy"
                class="invisible absolute -z-10 sm:visible sm:right-0 sm:-bottom-80 sm:h-48"
                src={GreenPolygon4}
                alt="green polygon"
              />
            </div>
            <div class="flex flex-col justify-center space-y-16 px-8 pt-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-0 sm:py-0 sm:pt-16">
              {featureDescriptions.map((feature, index) => (
                <FeatureDescription
                  key={index}
                  title={feature.title}
                  description={feature.description}
                  imageSrc={feature.imageSrc}
                  imageAlt={feature.imageAlt}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
