import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import Buzzer from '../../img/buzzer.png';
import BuzzerOpenDoor from '../../img/buzzer_open_door.png';
import GuestyConnect from '../../img/guesty_connect_lowkey.svg';

const HowItWorks = ({ theme = 'dark' }) => {
  return (
    <div className="px-10">
      <div className="flex flex-col items-center pb-0 pt-1 sm:pb-8">
        <SectionH2 title={'How It Works'} theme={theme} />
        <div
          className={`max-w-4xl space-y-3 text-center font-space-grotesk-regular ${
            theme === 'dark' ? 'text-white' : 'text-black'
          }`}
        >
          <p>Lowkey will allow guests to buzz themselves in so you don't have to answer buzzer calls.</p>
          <p>Guest check-in with Lowkey is completed in 3 easy steps.</p>
        </div>
      </div>

      <div className="sm:flex sm:flex-row sm:justify-center sm:pt-5">
        <div className="flex max-w-7xl flex-col justify-center space-y-16 px-2 pt-16 sm:flex-row sm:space-x-8 sm:space-y-0 sm:px-0 sm:py-0 lg:space-x-12">
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'1. Include buzzer access code in check-in instructions'}
            description={
              'Guesty reservations are automatically synced to generate an access code you can include in check-in instructions sent to your guests.'
            }
            imageSrc={GuestyConnect}
            imageAlt={'phone icon'}
            imageStyle="sm:h-80 lg:h-80 w-52 sm:w-60 pb-6"
            theme={theme}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'2. Guest dials your unit'}
            description={
              'Have your guest dial your unit from the buzzer, either by dialing your unit code or using the buzzer directory'
            }
            secondaryDescription={'Guests will be prompted to enter the Lowkey passcode after first dialing your unit'}
            imageSrc={Buzzer}
            imageAlt={'Building buzzer interface being pressed'}
            imageStyle="h-80 sm:h-68 lg:h-80 rounded-3xl"
            theme={theme}
          />
          <FeatureDescription
            customFontSize={'text-2xl lg:text-3xl'}
            title={'3. Guest is buzzed in'}
            description={'Lowkey will buzz in the guest without you having to answer your phone to buzz them in.'}
            imageSrc={BuzzerOpenDoor}
            imageAlt={'Guest being buzzed in and entering the building'}
            imageStyle="h-80 sm:h-68 lg:h-80 rounded-3xl"
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
