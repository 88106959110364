import React from 'react';
import SectionH2 from '../heading/SectionH2';
import ExpandableFAQ from '../core/ExpandableFAQ';

const FAQ = () => {
  return (
    <div class="flex flex-row justify-center px-10">
      <div class="flex max-w-7xl flex-col sm:flex-row">
        <div class="sm:w-1/3">
          <div class="flex flex-col items-center pb-5 pt-1 sm:pb-16">
            <SectionH2 customAlignment={'text-center sm:text-left'} title={'Frequently Asked Questions'} />
          </div>
        </div>
        <div class="flex-row justify-center space-y-4 sm:flex sm:w-2/3 sm:flex-none sm:gap-4 sm:space-y-0">
          <div class="flex flex-col gap-4 sm:flex-col sm:flex-wrap">
            <ExpandableFAQ
              expanded={false}
              title={'How will interacting with my buzzer change after I set up Lowkey?'}
              descriptions={[
                'By default Lowkey will forward calls from your buzzer directly to your personal phone – just as usual.',
                'However, if you have unlocked your door from within the Lowkey app you will no longer need to use your personal phone to answer calls from the buzzer. Instead, the buzzer will dial the virtual number associated with your Lowkey account and guests will be buzzed in automatically.',
              ]}
            />
            <div class="sm:hidden">
              <ExpandableFAQ
                expanded={false}
                title={'Is the Lowkey app secure?'}
                descriptions={[
                  'Yes, we acknowledge Lowkey is used for granting physical access to your building and thus we take security and data privacy extremely seriously.',
                  'Lowkey is built by engineers with years of professional experience at top companies like Amazon, Adobe, and Snap and is built with industry standard best practices in mind such as encryption, secure data storage, and robust authentication. Our services are highly reliable with an uptime record of over 99.99%.',
                  'Lowkey also includes an Activity History feature that helps to further ensure the security of your building. This feature allows you to view a record of all activity, including when individuals have accessed the building and when they have been granted access.',
                ]}
              />
            </div>
            <ExpandableFAQ
              expanded={false}
              title={'What is a virtual number?'}
              descriptions={[
                'A virtual number is a phone number that is not tied to a specific physical phone or SIM card. Instead, it is a virtual number that is associated with your Lowkey account and is used to interface securely with your buzzer system.',
              ]}
            />
            <ExpandableFAQ
              expanded={false}
              title={'How do I set up my virtual number?'}
              descriptions={[
                'Once you have purchased a subscription in the Lowkey app, you will receive a virtual number. Simply send this virtual number to your apartment or building manager to have it added to the buzzer. From then on, the buzzer will dial this virtual number rather than your personal phone.',
              ]}
            />
            <ExpandableFAQ
              expanded={false}
              title={'How do I purchase a subscription?'}
              descriptions={[
                'To purchase a subscription for Lowkey, simply download the app from the App Store or Google Play Store and follow the prompts to purchase a subscription. You can choose from a variety of subscription options depending on your needs.',
              ]}
            />
          </div>
          <div class="flex flex-col gap-4 sm:flex-col sm:flex-wrap">
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'Is the Lowkey app secure?'}
                descriptions={[
                  'Yes, we acknowledge Lowkey is used for granting physical access to your building and thus we take security and data privacy extremely seriously.',
                  'Lowkey is built by engineers with years of professional experience at top companies like Amazon, Adobe, and Snap and is built with industry standard best practices in mind such as encryption, secure data storage, and robust authentication. Our services are highly reliable with an uptime record of over 99.99%.',
                  'Lowkey also includes an Activity History feature that helps to further ensure the security of your building. This feature allows you to view a record of all activity, including when individuals have accessed the building and when they have been granted access.',
                ]}
              />
            </div>
            <ExpandableFAQ
              expanded={false}
              title={'Is Lowkey compatible with my buzzer system?'}
              descriptions={[
                'Lowkey is designed to work with all buzzer systems that dial out to a mobile phone number. If your buzzer dials your mobile phone number, you can use the Lowkey app to control it.',
                'If your buzzer system strictly requires an in-unit hard landline, you will not be able to use Lowkey.',
              ]}
            />
            <ExpandableFAQ
              expanded={false}
              title={'I already have a Google Voice number, ButterflyMX, etc. How can I set up Lowkey?'}
              descriptions={[
                'To set up Lowkey in this situation, you will configure the Google Voice/ButterflyMX app to forward calls to the virtual number you will receive from the Lowkey app rather than to your personal phone number.',
                'Any OTP codes required for phone number verification that are sent to your virtual number will be forwarded to your personal phone.',
                'If Google Voice does not text you a verification code, select the Verify by Phone option to receive a call with your OTP code.',
              ]}
            />
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'Can I forward calls for buzzer access to multiple phones?'}
                descriptions={[
                  'Yes, with Lowkey you can easily forward calls for buzzer access to multiple phones. From within the Lowkey app, simply invite the individuals you want to have access and they will be able to answer calls from the buzzer and grant access to your building.',
                ]}
              />
            </div>
            <div class="hidden sm:flex">
              <ExpandableFAQ
                expanded={false}
                title={'How does the Passcode feature work?'}
                descriptions={[
                  'Passcodes are a convenient and secure way to grant access to your building, as they can be easily changed or revoked at any time.',
                  'To create a passcode, you will need to log in to the app and press the "+" button to enter the creation screen. From there, you can create a new passcode and set your desired 4 digit passcode. Once you have created the passcode, you can share it with the individuals you want to grant access to your building. When dialing your number on the buzzer, they will be prompted to enter the passcode after which they may enter the 4 digit code using the buzzer dialpad to gain access to the building.',
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
