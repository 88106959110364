import React from 'react';
import iOSAppStore from './img/ios-app-store.svg';
import AndroidAppStore from './img/android-app-store.svg';
import LowkeyIcon from './img/lowkey_icon.svg';
import { useAnalyticsEventTracker } from './utils/analytics';

const DownloadPage = () => {
  const eventTracker = useAnalyticsEventTracker('Mobile Download');

  return (
    <div class="flex flex-grow flex-col items-center justify-center py-8 px-6 sm:p-16 sm:px-16">
      <div class="group relative">
        <div class="absolute inset-0.5 rounded-xl bg-gradient-to-br from-green-lowkey to-white opacity-75 blur-xl sm:transition sm:duration-1000 sm:group-hover:opacity-100 sm:group-hover:duration-200" />
        <div class="relative max-w-3xl rounded-xl bg-white p-5 sm:p-8">
          <div class="flex flex-col items-center space-y-2 text-center first:space-y-1">
            <div class="rounded-full bg-black p-4">
              <img src={LowkeyIcon} alt="Download Lowkey" />
            </div>
            <div class="font-syne-bold text-2xl sm:text-4xl">Lowkey</div>
            <div class="flex flex-col font-space-grotesk-regular text-slate-700 ">
              <a class="pb-4 hover:font-bold hover:text-green-lowkey" href="https://www.lowkeybuzzer.com">
                lowkeybuzzer.com
              </a>
              <p class=" font-space-grotesk-semi-bold">Make your apartment or condo buzzer smart</p>
            </div>

            <div class="flex flex-col space-y-5 pt-6 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0 sm:pt-8">
              <a
                href="https://apps.apple.com/app/apple-store/id1513284627?pt=121388959&ct=Download%20Page&mt=8"
                onClick={() => eventTracker('app_store_view', 'iOS_download_page', 'Download')}
              >
                <img src={iOSAppStore} alt={'iOS App Store Download'} class="mx-auto h-12 object-contain sm:h-14" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.lowkeyclient&utm_source=download-page"
                onClick={() => eventTracker('app_store_view', 'android_download_page', 'Download')}
              >
                <img
                  src={AndroidAppStore}
                  alt={'Google Play Store Download'}
                  class="mx-auto h-12 object-contain sm:h-14"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
